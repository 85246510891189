import React, { useState } from 'react';
import { TextField } from 'wix-ui-tpa/TextField';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { getAllCategories } from '../../../common/selectors/categories-selectors';
import { connect } from '../../../common/components/runtime-context';
import { getRoute } from '../../../common/router/router-selectors';
import { ROUTE_CREATE_POST } from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import {
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withPermissions from '../../hoc/with-permissions';
import { getOptionsFromCategories } from './get-options-from-categories';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';
import { MobileDrawer } from 'wix-ui-tpa/MobileDrawer';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { classes } from './category-dropdown-select-mobile.st.css';

const CategoryDropdownSelectMobile = ({
  t,
  categories,
  isDiscussionRoute,
  can,
  onChange,
  initiallySelectedId,
}) => {
  const postType = isDiscussionRoute ? DISCUSSION : QUESTION;
  const options = getOptionsFromCategories({ t, categories, can, postType });
  const selectedOption = options.find(o => o.id === initiallySelectedId);
  const [isOpen, setIsOpen] = useState(false);
  const closeDrawer = () => setIsOpen(false);

  return (
    <>
      <div onClick={() => setIsOpen(true)} data-hook="category-dropdown-select-trigger">
        <TextField
          maxLength={10}
          readOnly
          suffix={<ChevronDown />}
          theme="line"
          placeholder={t('category-dropdown-select.placeholder')}
          value={selectedOption?.value}
          className={classes.input}
        />
      </div>
      <MobileDrawer isOpen={isOpen} onRequestClose={closeDrawer}>
        <div className={classes.drawerWrapper}>
          <ActionsMenuLayout data-hook="category-dropdown-select-actions">
            {options.map(option => (
              <ActionsMenuLayout.Item
                key={option.id}
                onClick={() => {
                  onChange(categories.find(c => c._id === option.id));
                  closeDrawer();
                }}
                content={option.value}
                subtitle={option.subtitle}
                prefixIcon={option.icon}
                disabled={!option.isSelectable}
              />
            ))}
          </ActionsMenuLayout>
        </div>
      </MobileDrawer>
    </>
  );
};

CategoryDropdownSelectMobile.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  isDiscussionRoute: PropTypes.bool,
  can: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  initiallySelectedId: PropTypes.number,
};

const mapRuntimeToProps = state => ({
  categories: getAllCategories(state),
  isDiscussionRoute: getRoute(state).endsWith(ROUTE_CREATE_POST),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPermissions,
)(CategoryDropdownSelectMobile);
