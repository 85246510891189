import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SmListCompact from '../sm-list-compact';
import WhoLikedListHeaderMobile, { MOBILE_HEADER_HEIGHT } from './who-liked-list-header-mobile';
import WhoLikedListHeaderDesktop from './who-liked-list-header-desktop';
import Button from '../button';
import { connect } from '../../../common/components/runtime-context';
import { getPost } from '../../selectors/post-selectors';
import { getAnyComment } from '../../selectors/comment-selectors';
import { getWhoLiked } from '../../selectors/who-liked-selector';
import styles from './who-liked-list.scss';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { WHO_LIKED_PAGE_SIZE } from './who-like-constants';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';

class WhoLikedList extends Component {
  state = {
    isFetching: false,
  };
  componentDidMount() {
    const { totalItemCount, lastFetchedPage, initialItems } = this.props;

    if (
      (totalItemCount > 3 && lastFetchedPage === 0) ||
      (totalItemCount < 3 && lastFetchedPage === 0 && initialItems.length === 0) // after user itself likes something
    ) {
      // fetch the initial batch (page)
      this.fetchLikes();
    }
  }

  componentWillUnmount() {
    const { postId, commentId, resetLikesData } = this.props;

    resetLikesData({
      [commentId ? 'commentId' : 'postId']: commentId ? commentId : postId,
    });
  }

  fetchLikes = () => {
    const { postId, commentId } = this.props;
    this.setState({ isFetching: true });
    return this.props
      .fetchLikesDataPromisified({
        [commentId ? 'commentId' : 'postId']: commentId ? commentId : postId,
      })
      .then(() => this.setState({ isFetching: false }));
  };

  render() {
    const {
      totalItemCount,
      initialItems,
      isMobile,
      onClose,
      items,
      lastFetchedPage,
      t,
    } = this.props;

    const itemsLeft = Math.max(0, totalItemCount - lastFetchedPage * WHO_LIKED_PAGE_SIZE);
    return (
      <React.Fragment>
        {isMobile ? (
          <WhoLikedListHeaderMobile totalItemCount={totalItemCount} onClose={onClose} />
        ) : (
          <WhoLikedListHeaderDesktop totalItemCount={totalItemCount} />
        )}
        {this.state.isFetching && items.length === 0 ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <SmListCompact
            items={items.length > 0 ? items : initialItems}
            maxHeight={isMobile ? window.innerHeight - MOBILE_HEADER_HEIGHT : 332}
            lastItem={
              totalItemCount > 3 &&
              itemsLeft > 0 && (
                <Button
                  className={styles.button}
                  containerClassName={styles.buttonContainer}
                  onClick={this.fetchLikes}
                  isLoading={this.state.isFetching}
                  disabled={this.state.isFetching}
                  isSecondary
                >
                  {t('load-more.load', { postsLeft: itemsLeft })}
                </Button>
              )
            }
            showLoadMoreButton={items.length < totalItemCount}
          />
        )}
      </React.Fragment>
    );
  }
}

WhoLikedList.propTypes = {
  totalItemCount: PropTypes.number,
  initialItems: PropTypes.array,
  postId: PropTypes.array,
  commentId: PropTypes.array,
  isMobile: PropTypes.bool,
  lastFetchedPage: PropTypes.number,
  onClose: PropTypes.func,
  fetchLikesDataPromisified: PropTypes.func,
  t: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const entity = !ownProps.commentId
    ? getPost(state, ownProps.postId)
    : getAnyComment(state, ownProps.commentId, ownProps.postId);

  const whoLiked = getWhoLiked(state, ownProps.commentId || ownProps.postId);

  return {
    totalItemCount: entity.likeCount,
    initialItems: entity.likes,
    items: whoLiked.items,
    lastFetchedPage: whoLiked.lastPage,
    fetchLikesDataPromisified: actions.fetchLikesDataPromisified,
    resetLikesData: actions.resetLikesData,
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(WhoLikedList);
