import * as React from 'react';
import { Dropdown } from 'wix-ui-tpa/Dropdown';

import { classes } from './post-page-sidebar-pagination-select.st.css';

export const mapPaginationToOptions = (paginationState, t) => {
  return paginationState.map(({ pageNumber }) => ({
    id: pageNumber,
    isSelectable: true,
    value: t('pagination.page', { page: pageNumber }),
  }));
};

const SidebarPaginationSelect = ({ activePage, options, handleOnChange }) => {
  return (
    <Dropdown
      data-hook="comment-page-select"
      initialSelectedId={activePage?.pageNumber}
      options={options}
      onChange={handleOnChange}
      className={classes.dropdown}
      theme="line"
    />
  );
};

export default SidebarPaginationSelect;
