import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { SSR_SUCCESS } from '../../constants/interactions';
import { getHasReportedAppLoad } from '../../../common/store/app-loaded/app-loaded-selectors';
import {
  getIsForumLoadedOnInitialPage,
  isSSR,
  isDebug,
  isProduction,
} from '../../../common/store/basic-params/basic-params-selectors';

const shouldReportSSRSuccess = () => {
  return (
    typeof window !== 'undefined' && !window.clientSideRender && window.sssr && window.sssr.success
  );
};

export class AppLoaded extends Component {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  init() {
    // we check if it's not SSR, because if it is true, then it means this is initial hydration with SSR props and there is no controller yet
    if (!this.props.isSSR && !this.props.hasReported && !this.timeout) {
      this.timeout = setTimeout(() => this.props.applicationLoaded()); // Ensures that masonry is rendered
      if (typeof performance !== 'undefined') {
        performance.mark && performance.mark('communities-app-loaded');
      }
    }
  }

  cleanUp() {
    clearTimeout(this.timeout);
  }

  render() {
    return null;
  }
}

AppLoaded.propTypes = {
  applicationLoaded: PropTypes.func,
  hasReported: PropTypes.bool,
  isSSR: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  applicationLoaded: () => {
    const shouldLog = isDebug(state) || !isProduction(state);

    shouldLog && console.log('applicationLoaded');
    // if forum is first page to be loaded (means it was SSR), then we report track SSR.
    // if it was successful then on initial hydration there will be SSR true on state.
    if (getIsForumLoadedOnInitialPage(state)) {
      actions.interactionStarted(SSR_SUCCESS);
      if (shouldReportSSRSuccess()) {
        shouldLog && console.log('Report SSR success');
        actions.interactionEnded(SSR_SUCCESS);
      }
    }
    host.appLoadBI.loaded();
  },
  isSSR: isSSR(state),
  hasReported: getHasReportedAppLoad(state),
});

export default connect(mapRuntimeToProps)(AppLoaded);
